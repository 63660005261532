import React from "react";

const AddIcon = ({ width, height, color, margin }) => {
  return (
    <svg
      width={width ? width : "20"}
      height={height ? height : "20"}
      style={{ margin: margin }}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.83 0H15.16C18.56 0 20.5 1.92 20.5 5.33V14.67C20.5 18.06 18.57 20 15.17 20H5.83C2.42 20 0.5 18.06 0.5 14.67V5.33C0.5 1.92 2.42 0 5.83 0ZM11.32 10.83H14.16C14.62 10.82 14.99 10.45 14.99 9.99C14.99 9.53 14.62 9.16 14.16 9.16H11.32V6.34C11.32 5.88 10.95 5.51 10.49 5.51C10.03 5.51 9.66 5.88 9.66 6.34V9.16H6.83C6.61 9.16 6.4 9.25 6.24 9.4C6.09 9.56 6 9.769 6 9.99C6 10.45 6.37 10.82 6.83 10.83H9.66V13.66C9.66 14.12 10.03 14.49 10.49 14.49C10.95 14.49 11.32 14.12 11.32 13.66V10.83Z"
        fill={color ? color : "white"}
      />
    </svg>
  );
};

export default AddIcon;
