import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-hot-toast";
import { useHistory, useParams } from "react-router";
import { checkForgotPasswordCode, resetPassword } from "src/api/index";
import ForgotPasswordImage from "src/assets/images/ForgotPassword.svg";
import { password } from "src/schema/forgotPassword.schema";
import Navbar from "src/views/components/layout/Navbar";
import errorAlert from "src/views/components/reusable/alerts/ErrorAlert";
import successAlert from "src/views/components/reusable/alerts/SuccessAlert";
import {
  HQForm,
  HQInput,
} from "src/views/components/reusable/input/FormControl";
import Loading from "src/views/components/reusable/Loading";
import "../register/register.css";

const ResetPassword = () => {
  const passwordRef = useRef();
  const confirmPasswordRef = useRef();

  const { code } = useParams();
  const history = useHistory();
  const [validVisitor, setValidVisitor] = useState(false);

  useEffect(() => {
    const checkCode = async () => {
      try {
        const { data } = await checkForgotPasswordCode({ code });

        if (data.status) {
          successAlert("Reset your password here");
          return setValidVisitor(true);
        }

        errorAlert("Permission denied");
        history.push("/login");
      } catch (error) {
        errorAlert("Something went wrong");
        history.replace("/login");
      }
    };

    checkCode();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code]);

  const onSubmit = async (data) => {
    const toastId = toast.loading("Loading...");
    const info = {
      ...data,
      code,
    };

    try {
      const { data } = await resetPassword(info);

      if (data.status) {
        toast.success(data.message, {
          id: toastId,
        });
        return history.push("/login");
      }
      toast.error(data.message, {
        id: toastId,
      });
    } catch (err) {
      toast.error(err.message, {
        id: toastId,
      });
    }
  };

  if (!validVisitor) return <Loading />;

  return (
    <>
      <Navbar />
      <div className="login">
        <div className="login-container">
          <div className="login-banner-container px-4 bg-primary-100-dark rounded-left d-flex justify-content-center align-items-center">
            <img
              src={ForgotPasswordImage}
              style={{ width: "300px" }}
              alt=""
              className="img-fluid px-3"
            />
          </div>
          <div className="login-input-container border-gray-2 p-md-4 p-sm-0 rounded-right">
            <HQForm
              className="form-container my-md-3 p-md-5 "
              onSubmit={onSubmit}
              schema={password}
            >
              <h4 className="text-black mb-4">Reset Password</h4>

              <HQInput name="password" label="Password" type="password" />
              <HQInput
                name="confirmPassword"
                label="Confirm Password"
                type="password"
              />
              <div className="d-flex justify-content-end mb-0 mt-3">
                <button className="button-md mb-0" type="submit">
                  Reset
                </button>
              </div>
            </HQForm>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
