import axios from "axios";
import toast from "react-hot-toast";

let url = process.env.REACT_APP_API_local;
if (process.env.REACT_APP_ENV === "production") {
  url = process.env.REACT_APP_API_production;
} else if (process.env.REACT_APP_ENV === "development") {
  url = process.env.REACT_APP_API_development;
}

export const Axios = axios.create({
  baseURL: url,
  timeout: 10000,
});

Axios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("ph_token");
    config.headers.Authorization = `Bearer ${token}`;
    return config;
  },
  (error) => {
    // Do something with request error
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
    } else {
      // Something happened in setting up the request that triggered an Error
    }

    return error;
  }
);

Axios.interceptors.response.use(
  (response) => {
    // extract data from response
    return response.data.data;
  },
  (error) => {
    // dismiss the loading indicator
    toast.dismiss();
    // Do something with request error
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      // if(error.response.status === 500) {
      // }

      toast.error(error.response?.data?.message || "Something went wrong");
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      toast.error(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
    }
    return error;
  }
);

// fetcher function to use with useSWR
// Pass url to response handler
// recieves url as param
export const fetcher = async (url) => {
  return Axios({
    url,
  });
};
