import Joi from "joi";
import { PASSWORD_REGEX } from "src/constants/constants";


export const email = Joi.object({
  email: Joi.string()
    .email({ minDomainSegments: 2, tlds: { allow: ["com"] } })
    .required()
    .messages({
      "string.base": "Invalid Email",
      "any.required": "Email is required",
    }),
});


export const password = Joi.object({
  password: Joi.string()
    .required()
    .min(6)
    .max(16)
    .pattern(PASSWORD_REGEX)
    .messages({
      "string.empty": "Current Password is required",
      "string.min": "Password must be minimum 6 characters in length.",
      "string.max": "Password can be max 16 characters in length.",
      "string.pattern.base":
        "Password must contain 1 lowercase, 1 uppercase letter and 1 number",
    }),
  confirmPassword: Joi.string().required().valid(Joi.ref("password")).messages({
    "string.empty": "Current Password is required",
    "any.only": "Passwords don't match",
  }),
});