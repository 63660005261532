import React from "react";

const Loading = () => {
  return (
    <div
      class="spinner-border text-primary-100 position-absolute"
      style={{ top: "50%", left: "50%" }}
      role="status"
    >
      <span class="sr-only">Loading...</span>
    </div>
  );
};

export default Loading;
