import React, { useState } from "react";

const BinIcon = ({ size = "20" }) => {
  const [hover, setHover] = useState(false);
  return (
    <svg
      width={size}
      height={size}
      viewBox="5 5 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      // onMouseEnter={() => setHover(true)}
      // onMouseLeave={() => setHover(false)}
    >
      {hover && <circle cx="12" cy="12" r="12" fill="#F5F5F5" />}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.5247 7.49596C17.7841 7.49596 18 7.71129 18 7.98529V8.23862C18 8.50595 17.7841 8.72795 17.5247 8.72795H6.4759C6.21591 8.72795 6 8.50595 6 8.23862V7.98529C6 7.71129 6.21591 7.49596 6.4759 7.49596H8.41971C8.81457 7.49596 9.1582 7.2153 9.24703 6.8193L9.34882 6.36464C9.50702 5.74531 10.0277 5.33398 10.6235 5.33398H13.3765C13.9659 5.33398 14.4923 5.74531 14.6447 6.33197L14.7536 6.81864C14.8418 7.2153 15.1854 7.49596 15.5809 7.49596H17.5247ZM16.5372 16.7567C16.7401 14.8654 17.0955 10.3721 17.0955 10.3267C17.1084 10.1894 17.0637 10.0594 16.9749 9.95473C16.8795 9.85674 16.7589 9.79874 16.626 9.79874H7.379C7.24544 9.79874 7.11836 9.85674 7.03018 9.95473C6.94071 10.0594 6.89662 10.1894 6.9031 10.3267C6.90429 10.3351 6.91704 10.4933 6.93836 10.758C7.03305 11.9335 7.29677 15.2074 7.46718 16.7567C7.58778 17.898 8.33664 18.6153 9.42137 18.6413C10.2584 18.6606 11.1207 18.6673 12.0025 18.6673C12.8331 18.6673 13.6766 18.6606 14.5396 18.6413C15.6619 18.622 16.4101 17.9173 16.5372 16.7567Z"
        // fill={hover ? "#adadad" : "#d6d6d6"}
        fill="#858585"
      />
    </svg>
  );
};

export default BinIcon;
