import React from "react";
// className={"filter-input" + (className ? " " + className : "")}
export const HQColumn = ({ children, isRight, className }) => {
  return (
    <div
      className={
        className
          ? className
          : "col-md-6" + (isRight ? " margin-left-on-child" : "")
      }
    >
      {children}
    </div>
  );
};
