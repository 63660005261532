// Evaluations
export const INTERN = "Intern";
export const YEARLY = "Yearly";
export const PROBATION = "Probation";

// Statuses
export const PENDING = "Pending";
export const ONGOING = "On-Going";
export const DONE = "Done";

export const PASSWORD_REGEX =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d#?!@$%^&*-]{6,}$/;
export const LINKEDIN_REGEX =
  /^(http(s)?:\/\/)?(www\.)?linkedin\.com\/in\/[A-z0-9_-]+\/?/;
export const FACEBOOK_REGEX =
  /^(http(s)?:\/\/)?((www|m)\.)?facebook\.com\/.(?:(?:\w)*#!\/)?(?:pages\/)?(?:[\w\-]*\/)*([\w\-\.]*)/;



export const allBanksList = [
  { id: 1, bankName: "AB Bank Limited." },
  { id: 2, bankName: "Agrani Bank Ltd." },
  { id: 3, bankName: "Al-Arafah Islami Bank Ltd." },
  { id: 4, bankName: "BENGAL COMMERCIAL BANK LIMITED." },
  { id: 5, bankName: "Bangladesh Bank." },
  { id: 6, bankName: "Bangladesh Commerce Bank Ltd." },
  { id: 7, bankName: "Bangladesh Development Bank Ltd." },
  { id: 8, bankName: "Bangladesh Krishi Bank." },
  { id: 9, bankName: "Bangladesh Samabaya Bank Ltd." },
  { id: 10, bankName: "Bank Al-Falah Ltd." },
  { id: 11, bankName: "Bank Asia Ltd." },
  { id: 12, bankName: "Basic Bank Ltd." },
  { id: 13, bankName: "Brac Bank Ltd." },
  { id: 14, bankName: "City Bank Ltd" },
  { id: 15, bankName: "Dhaka Bank Ltd." },
  { id: 16, bankName: "Dutch-Bangla Bank Ltd." },
  { id: 17, bankName: "Eastern Bank Ltd." },
  { id: 18, bankName: "Exim Bank Ltd." },
  { id: 19, bankName: "First Security Islami Bank Ltd." },
  { id: 20, bankName: "Global Islami Bank Ltd." },
  { id: 21, bankName: "HSBC." },
  { id: 22, bankName: "Habib Bank Ltd." },
  { id: 23, bankName: "ICB Islamic Bank Ltd." },
  { id: 24, bankName: "IFIC Bank Limited." },
  { id: 25, bankName: "Islami Bank Bangladesh Ltd." },
  { id: 26, bankName: "Jamuna Bank Ltd." },
  { id: 27, bankName: "Janata Bank Ltd." },
  { id: 28, bankName: "Meghna Bank Limited." },
  { id: 29, bankName: "Mercantile Bank Ltd." },
  { id: 30, bankName: "Midland Bank Limited." },
  { id: 31, bankName: "Modhumoti Bank Limited." },
  { id: 32, bankName: "Mutual Trust Bank Ltd." },
  { id: 33, bankName: "NRB Bank Limited." },
  { id: 34, bankName: "National Bank Ltd." },
  { id: 35, bankName: "National Credit & Commerce Bank Ltd." },
  { id: 36, bankName: "One Bank Ltd." },
  { id: 37, bankName: "Padma Bank Limited." },
  { id: 38, bankName: "Prime Bank Ltd." },
  { id: 39, bankName: "Pubali Bank Ltd." },
  { id: 40, bankName: "Rajshahi Krishi Unnayan." },
  { id: 41, bankName: "Rupali Bank Ltd." },
  { id: 42, bankName: "SBAC Bank Limited." },
  { id: 43, bankName: "Shahjalal Islami Bank Ltd." },
  { id: 44, bankName: "Shimanto Bank Limited." },
  { id: 45, bankName: "Social Islami Bank Ltd." },
  { id: 46, bankName: "Sonali Bank Ltd." },
  { id: 47, bankName: "Southeast Bank Ltd." },
  { id: 48, bankName: "Standard Bank Ltd." },
  { id: 49, bankName: "Standard Chartered Bank." },
  { id: 50, bankName: "The Premier Bank Ltd." },
  { id: 51, bankName: "Trust Bank Ltd." },
  { id: 52, bankName: "Union Bank Ltd." },
  { id: 53, bankName: "United Commercial Bank Ltd." },
  { id: 54, bankName: "Uttara Bank Ltd" },
];
export const freelanceStatus = {
  pending: "Pending",
  approved: "Approved",
  reject: "Rejected",
  paid: "Paid",
  reimbursed: "Reimbursed",
}