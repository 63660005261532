const DeleteIconNew = ({ size = 24, color = "#b7b2b2", onClick }) => {
  const iconStyle = {
    width: size,
    height: size,
    cursor: "pointer",
    fill: color,
  };

  return (
    <svg
      onClick={onClick}
      style={iconStyle}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      aria-hidden="true"
    >
      <path d="M3 6h18v2H3V6zm2 2v12a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V8H5zm3.5-4h7l1 1H19v2H5V5h3.5l1-1zM9 10h2v8H9v-8zm4 0h2v8h-2v-8z" />
    </svg>
  );
};

export default DeleteIconNew;
